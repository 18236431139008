import React, { Component } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

// Utils
import {
  getRandomInt, getTimeInMilan, getTransformTranslation, scaleVectorGroup,
} from '../../../../../utils/helper';

class SkylineLandscape extends Component {
  componentDidUpdate() {
    const { chartWidth } = this.props;

    const originalWidth = 540;
    const strokeScale = (chartWidth / originalWidth) * (chartWidth < 480 ? 1.45 : 1);
    const scale = (chartWidth / originalWidth);

    // Modify all strokeWidths
    d3.selectAll('path')
      .attr('stroke-width', strokeScale);

    d3.selectAll('polyline')
      .attr('stroke-width', strokeScale);

    d3.selectAll('rect')
      .attr('stroke-width', strokeScale);

    d3.selectAll('circle')
      .attr('stroke-width', strokeScale);

    d3.selectAll('#road')
      .attr('stroke-width', strokeScale * 2);

    scaleVectorGroup('#skyline', scale);

    const car = d3.select('#car');
    const gelateria = d3.select('#gelateria');
    const carPosition = getTransformTranslation(car.attr('transform'));
    const gelateriaPosition = getTransformTranslation(gelateria.attr('transform'));

    // Move the car
    function animateCar() {
      const stopAtGelateria = getRandomInt(0, 2);
      const initialPosition = `translate(${-50}, ${carPosition[1]})`;
      const finalPosition = `translate(${originalWidth}, ${carPosition[1]})`;
      const stopAtPosition = `translate(${stopAtGelateria ? gelateriaPosition[0] + 8 : originalWidth}, ${carPosition[1]})`;

      car
        .attr('transform', initialPosition)
        .transition()
        .ease(d3.easeLinear)
        .delay(getRandomInt(1000, 2000))
        .duration(getRandomInt(6000, 9000))
        .attr('transform', stopAtPosition)
        .transition()
        .ease(d3.easeLinear)
        .delay(stopAtGelateria ? getRandomInt(8000, 10000) : 0)
        .attr('transform', stopAtGelateria ? `translate(${originalWidth}, ${carPosition[1]})` : finalPosition)
        .on('end', animateCar);
    }

    function applySeason() {
      const currentMonth = getTimeInMilan().getMonth() + 1;

      if (currentMonth >= 3 && currentMonth <= 5) {
        // Spring
        d3.select('#treeline').selectAll('path').attr('fill', '#00FFBF');
      } else if (currentMonth >= 6 && currentMonth <= 8) {
        // Summer
        d3.select('#treeline').selectAll('path').attr('fill', '#03EB7D');
      } else if (currentMonth >= 9 && currentMonth <= 11) {
        // Autumn
        d3.select('#treeline').selectAll('path').attr('fill', '#F1D659');
      } else {
        // Winter
        d3.select('#treeline').selectAll('path').attr('fill', '#CCCCCC');
      }
    }

    animateCar();
    applySeason();
  }

  render() {
    const { chartWidth } = this.props;

    return (
      <div id="chart">
        <svg width={chartWidth} height={chartWidth / 2.5}>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="skyline" transform="translate(0, 0)">
              <g transform="translate(0, 60)">
                <g id="torre-solari" transform="translate(10.000000, 24.344369)" stroke="#000000" strokeWidth="1.25">
                  <rect id="building" x="0.625" y="0.625" width="27.0543478" height="129.75" />
                  <g id="floors" transform="translate(0.673913, 8.187500)" strokeLinecap="square">
                    <path d="M0,0.682291667 L15.8369565,0.682291667" id="floor" />
                    <path d="M0,10.9166667 L15.8369565,10.9166667" id="floor" />
                    <path d="M0,21.1510417 L15.8369565,21.1510417" id="floor" />
                    <path d="M0,31.3854167 L15.8369565,31.3854167" id="floor" />
                    <path d="M0,41.6197917 L15.8369565,41.6197917" id="floor" />
                    <path d="M0,51.8541667 L15.8369565,51.8541667" id="floor" />
                    <path d="M0,62.0885417 L15.8369565,62.0885417" id="floor" />
                    <path d="M0,72.3229167 L15.8369565,72.3229167" id="floor" />
                    <path d="M0,82.5572917 L15.8369565,82.5572917" id="floor" />
                    <path d="M0,92.7916667 L15.8369565,92.7916667" id="floor" />
                    <path d="M0,103.026042 L15.8369565,103.026042" id="floor" />
                    <path d="M0,113.260417 L15.8369565,113.260417" id="floor" />
                  </g>
                  <g id="balconies" transform="translate(28.304348, 12.622396)" strokeLinecap="square">
                    <path d="M-1.42108547e-14,0.341145833 L2.69565217,0.341145833" id="balcony" />
                    <path d="M-1.42108547e-14,10.5755208 L2.69565217,10.5755208" id="balcony" />
                    <path d="M-1.42108547e-14,20.8098958 L2.69565217,20.8098958" id="balcony" />
                    <path d="M-1.42108547e-14,31.0442708 L2.69565217,31.0442708" id="balcony" />
                    <path d="M-1.42108547e-14,41.2786458 L2.69565217,41.2786458" id="balcony" />
                    <path d="M-1.42108547e-14,51.5130208 L2.69565217,51.5130208" id="balcony" />
                    <path d="M-1.42108547e-14,61.7473958 L2.69565217,61.7473958" id="balcony" />
                    <path d="M-1.42108547e-14,71.9817708 L2.69565217,71.9817708" id="balcony" />
                    <path d="M-1.42108547e-14,82.2161458 L2.69565217,82.2161458" id="balcony" />
                    <path d="M-1.42108547e-14,92.4505208 L2.69565217,92.4505208" id="balcony" />
                    <path d="M-1.42108547e-14,102.684896 L2.69565217,102.684896" id="balcony" />
                    <path d="M-1.42108547e-14,112.919271 L2.69565217,112.919271" id="balcony" />
                  </g>
                  <g id="windows" transform="translate(20.891304, 8.187500)">
                    <rect id="window" x="0.625" y="0.625" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="10.859375" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="21.09375" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="21.09375" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="31.328125" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="41.5625" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="51.796875" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="62.03125" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="72.265625" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="82.5" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="92.734375" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="102.96875" width="2.11956522" height="6.9375" />
                    <rect id="window" x="0.625" y="113.203125" width="2.11956522" height="6.9375" />
                  </g>
                </g>
                <g id="allianz-tower" transform="translate(453.000000, 37.344369)" stroke="#000000">
                  <rect id="building" strokeWidth="1.25" x="0.625" y="20.9698276" width="27.75" height="96.4051724" />
                  <g id="floors" transform="translate(2.761905, 27.126437)" strokeWidth="0.75">
                    <rect id="floor" x="0.375" y="0.375" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="7.1566092" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="13.9382184" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="20.7198276" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="27.5014368" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="34.283046" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="41.0646552" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="47.8462644" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="54.6278736" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="61.4094828" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="68.191092" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="74.9727011" width="22.7261905" height="1.96264368" />
                    <rect id="floor" x="0.375" y="81.7543103" width="22.7261905" height="1.96264368" />
                  </g>
                  <g id="spire" transform="translate(7.595238, 0.000000)" strokeWidth="1.25">
                    <rect id="base" x="0.625" y="19.6135057" width="12.5595238" height="1" />
                    <rect x="6.83928571" y="0.625" width="1" height="17.7385057" />
                  </g>
                </g>
                <g id="generali-tower" transform="translate(489.000000, 33.344369)" stroke="#000000" strokeLinecap="square" strokeWidth="1.25">
                  <g id="facade" transform="translate(2.017241, 1.359116)">
                    <path d="M11.0948276,0.679558011 C11.0948276,4.38089368 11.0948276,16.1664271 11.0948276,36.0361584 C11.0948276,55.9058896 7.39655172,84.2142788 -1.42108547e-14,120.961326" id="line" />
                    <path d="M13.112069,0.679558011 C13.112069,4.37043793 13.112069,16.1226789 13.112069,35.936281 C13.112069,55.7498831 9.4137931,83.978305 2.01724138,120.621547" id="line" />
                    <path d="M15.1293103,0.679558011 C15.1293103,4.37043793 15.1293103,16.1226789 15.1293103,35.936281 C15.1293103,55.7498831 11.4310345,83.978305 4.03448276,120.621547" id="line" />
                    <path d="M17.1465517,0 C17.1465517,3.69087992 17.1465517,15.4431209 17.1465517,35.256723 C17.1465517,55.070325 13.4482759,83.298747 6.05172414,119.941989" id="line" />
                    <path d="M19.1637931,0.679558011 C19.1637931,4.37043793 19.1637931,16.1226789 19.1637931,35.936281 C19.1637931,55.7498831 15.4655172,83.978305 8.06896552,120.621547" id="line" />
                    <path d="M21.1810345,0.679558011 C21.1810345,4.37043793 21.1810345,16.1226789 21.1810345,35.936281 C21.1810345,55.7498831 17.4827586,83.978305 10.0862069,120.621547" id="line" />
                    <path d="M23.5431034,0.695858322 C23.5431034,4.36514528 23.5431034,16.0486315 23.5431034,35.7463169 C23.5431034,55.4440024 19.7327586,83.507278 12.112069,119.936144" id="line" />
                    <path d="M25.5431034,0.695858322 C25.5431034,4.36514528 25.5431034,16.0486315 25.5431034,35.7463169 C25.5431034,55.4440024 21.7327586,83.507278 14.112069,119.936144" id="line-copy" />
                    <path d="M28.2327586,0.695858322 C28.2327586,4.36514528 28.2327586,16.0486315 28.2327586,35.7463169 C28.2327586,55.4440024 24.4224138,83.507278 16.8017241,119.936144" id="line" />
                    <path d="M28.5775862,3.39779006 C28.5775862,7.02599626 28.5775862,18.578676 28.5775862,38.0558293 C28.5775862,57.5329826 25.4396552,85.1681482 19.1637931,120.961326" id="line" />
                    <path d="M28.5775862,1.37541633 C28.5775862,5.04470329 28.5775862,16.7281895 28.5775862,36.4258749 C28.5775862,56.1235604 25.887931,84.3020441 20.5086207,120.961326" id="line" />
                    <path d="M27.5775862,1.37541633 C27.5775862,5.04470329 27.5775862,16.7281895 27.5775862,36.4258749 C27.5775862,56.1235604 24.887931,84.3020441 19.5086207,120.961326" id="line-copy-2" />
                    <path d="M30.5948276,1.35911602 C30.5948276,6.28646171 30.5948276,18.3563771 30.5948276,37.568862 C30.5948276,56.781347 28.5948276,84.3780984 24.5948276,120.359116" id="line" />
                    <path d="M32.612069,2.03867403 C32.612069,6.50421045 32.612069,18.3167729 32.612069,37.4763613 C32.612069,56.6359498 31.2787356,84.1567207 28.612069,120.038674" id="line" />
                    <path d="M34.6293103,3.39779006 C34.6293103,6.97738719 34.6293103,18.3752893 34.6293103,37.5914965 C34.6293103,56.8077037 33.9626437,84.4098015 32.6293103,120.39779" id="line" />
                  </g>
                  <path d="M5.68434189e-14,120.963226 C5.4704357,92.6958899 8.83250467,71.9185376 10.0862069,58.6311685 C11.2974291,45.7940242 11.3871444,26.8841951 11.0948276,4.04850227 C11.0928747,3.89594332 11.2238929,1.62362395 14.4568966,1.01613193 C18.0431034,0.342271852 23.5344828,0.566891877 30.9310345,1.68999201 L38.6637931,4.72236235 L39,121.637086" id="building" />
                </g>
                <g id="gelateria" transform="translate(300.000000, 124.344369)">
                  <path d="M7.11071429,31.375 L48.375,31.375 L48.375,16.7111111 C48.375,14.8471501 46.863961,13.3361111 45,13.3361111 L10.4857143,13.3361111 C8.62175326,13.3361111 7.11071429,14.8471501 7.11071429,16.7111111 L7.11071429,31.375 Z" id="building" stroke="#000000" strokeWidth="1.25" />
                  <g id="ice-cream" transform="translate(11.609000, 13.893898) rotate(-24.000000) translate(-11.609000, -13.893898) translate(5.109000, 2.393898)" stroke="#000000" strokeWidth="0.5">
                    <path d="M3.79963116,9.64444444 L5.50262121,17.423363 C5.6207322,17.9628704 6.15383724,18.3044797 6.69334464,18.1863687 C7.07994749,18.1017322 7.38026467,17.7970605 7.45932752,17.4092794 L9.04246288,9.64444444 L3.79963116,9.64444444 Z" id="cone" fill="#FFA86D" strokeLinecap="square" />
                    <g id="circles">
                      <ellipse id="circle" fill="#FC0B42" cx="4.11428571" cy="6.88888889" rx="4.11428571" ry="4.13333333" />
                      <ellipse id="circle" fill="#03EBB0" cx="5.82857143" cy="3.1" rx="3.08571429" ry="3.1" />
                      <ellipse id="circle" fill="#016EFF" cx="8.91428571" cy="6.88888889" rx="3.42857143" ry="3.44444444" />
                    </g>
                  </g>
                  <text id="sign" fontFamily="AvenirNext-Italic, Avenir Next" fontSize="6" fontStyle="italic" fontWeight="normal" fill="#000000">
                    <tspan x="20.5142857" y="21.1555556">Gelateria</tspan>
                  </text>
                  <rect id="window" stroke="#000000" strokeWidth="1.25" x="11.225" y="25.0472222" width="24.8071429" height="6.32777778" rx="1" />
                  <rect id="door" stroke="#000000" strokeWidth="1.25" x="40.025" y="25.0472222" width="4.23571429" height="6.32777778" rx="1" />
                </g>
                <g id="palazzo-lombardia" transform="translate(225.000000, 32.344369)">
                  <path d="M4.72704082,1.92196541 L4.72704082,122.693681 L30.8239796,122.693681 L30.8239796,1.92196541 C25.0453195,1.28422989 20.6931484,0.965659341 17.7755102,0.965659341 C14.8578721,0.965659341 10.5057009,1.28422989 4.72704082,1.92196541 Z" id="building-left" stroke="#000000" strokeWidth="1.25" />
                  <g id="floors-left" transform="translate(5.127551, 8.857143)" fill="#D8D8D8" stroke="#000000" strokeLinecap="square" strokeWidth="0.5">
                    <path d="M0,1.02197802 C5.62131519,0.340659341 9.83730159,0 12.6479592,0 C15.4586168,0 19.6746032,0.340659341 25.2959184,1.02197802" id="floors" />
                    <path d="M0,3.74725275 C5.62131519,3.06593407 9.83730159,2.72527473 12.6479592,2.72527473 C15.4586168,2.72527473 19.6746032,3.06593407 25.2959184,3.74725275" id="floors" />
                    <path d="M0,6.47252747 C5.62131519,5.79120879 9.83730159,5.45054945 12.6479592,5.45054945 C15.4586168,5.45054945 19.6746032,5.79120879 25.2959184,6.47252747" id="floors" />
                    <path d="M0,9.1978022 C5.62131519,8.51648352 9.83730159,8.17582418 12.6479592,8.17582418 C15.4586168,8.17582418 19.6746032,8.51648352 25.2959184,9.1978022" id="floors" />
                    <path d="M0,11.9230769 C5.62131519,11.2417582 9.83730159,10.9010989 12.6479592,10.9010989 C15.4586168,10.9010989 19.6746032,11.2417582 25.2959184,11.9230769" id="floors" />
                    <path d="M0,14.6483516 C5.62131519,13.967033 9.83730159,13.6263736 12.6479592,13.6263736 C15.4586168,13.6263736 19.6746032,13.967033 25.2959184,14.6483516" id="floors" />
                    <path d="M0,17.3736264 C5.62131519,16.6923077 9.83730159,16.3516484 12.6479592,16.3516484 C15.4586168,16.3516484 19.6746032,16.6923077 25.2959184,17.3736264" id="floors" />
                    <path d="M0,20.0989011 C5.62131519,19.4175824 9.83730159,19.0769231 12.6479592,19.0769231 C15.4586168,19.0769231 19.6746032,19.4175824 25.2959184,20.0989011" id="floors" />
                    <path d="M0,22.8241758 C5.62131519,22.1428571 9.83730159,21.8021978 12.6479592,21.8021978 C15.4586168,21.8021978 19.6746032,22.1428571 25.2959184,22.8241758" id="floors" />
                    <path d="M0,25.5494505 C5.62131519,24.8681319 9.83730159,24.5274725 12.6479592,24.5274725 C15.4586168,24.5274725 19.6746032,24.8681319 25.2959184,25.5494505" id="floors" />
                    <path d="M0,28.2747253 C5.62131519,27.5934066 9.83730159,27.2527473 12.6479592,27.2527473 C15.4586168,27.2527473 19.6746032,27.5934066 25.2959184,28.2747253" id="floors" />
                    <path d="M0,31 C5.62131519,30.3186813 9.83730159,29.978022 12.6479592,29.978022 C15.4586168,29.978022 19.6746032,30.3186813 25.2959184,31" id="floors" />
                    <path d="M0,33.7252747 C5.62131519,33.043956 9.83730159,32.7032967 12.6479592,32.7032967 C15.4586168,32.7032967 19.6746032,33.043956 25.2959184,33.7252747" id="floors" />
                    <path d="M0,36.4505495 C5.62131519,35.7692308 9.83730159,35.4285714 12.6479592,35.4285714 C15.4586168,35.4285714 19.6746032,35.7692308 25.2959184,36.4505495" id="floors" />
                    <path d="M0,39.1758242 C5.62131519,38.4945055 9.83730159,38.1538462 12.6479592,38.1538462 C15.4586168,38.1538462 19.6746032,38.4945055 25.2959184,39.1758242" id="floors" />
                    <path d="M0,41.9010989 C5.62131519,41.2197802 9.83730159,40.8791209 12.6479592,40.8791209 C15.4586168,40.8791209 19.6746032,41.2197802 25.2959184,41.9010989" id="floors" />
                    <path d="M0,44.6263736 C5.62131519,43.9450549 9.83730159,43.6043956 12.6479592,43.6043956 C15.4586168,43.6043956 19.6746032,43.9450549 25.2959184,44.6263736" id="floors" />
                    <path d="M0,47.3516484 C5.62131519,46.6703297 9.83730159,46.3296703 12.6479592,46.3296703 C15.4586168,46.3296703 19.6746032,46.6703297 25.2959184,47.3516484" id="floors" />
                    <path d="M0,50.0769231 C5.62131519,49.3956044 9.83730159,49.0549451 12.6479592,49.0549451 C15.4586168,49.0549451 19.6746032,49.3956044 25.2959184,50.0769231" id="floors" />
                    <path d="M0,52.8021978 C5.62131519,52.1208791 9.83730159,51.7802198 12.6479592,51.7802198 C15.4586168,51.7802198 19.6746032,52.1208791 25.2959184,52.8021978" id="floors" />
                    <path d="M0,55.5274725 C5.62131519,54.8461538 9.83730159,54.5054945 12.6479592,54.5054945 C15.4586168,54.5054945 19.6746032,54.8461538 25.2959184,55.5274725" id="floors" />
                    <path d="M0,58.2527473 C5.62131519,57.5714286 9.83730159,57.2307692 12.6479592,57.2307692 C15.4586168,57.2307692 19.6746032,57.5714286 25.2959184,58.2527473" id="floors" />
                    <path d="M0,60.978022 C5.62131519,60.2967033 9.83730159,59.956044 12.6479592,59.956044 C15.4586168,59.956044 19.6746032,60.2967033 25.2959184,60.978022" id="floors" />
                    <path d="M0,63.7032967 C5.62131519,63.021978 9.83730159,62.6813187 12.6479592,62.6813187 C15.4586168,62.6813187 19.6746032,63.021978 25.2959184,63.7032967" id="floors" />
                    <path d="M0,66.4285714 C5.62131519,65.7472527 9.83730159,65.4065934 12.6479592,65.4065934 C15.4586168,65.4065934 19.6746032,65.7472527 25.2959184,66.4285714" id="floors" />
                    <path d="M0,69.1538462 C5.62131519,68.4725275 9.83730159,68.1318681 12.6479592,68.1318681 C15.4586168,68.1318681 19.6746032,68.4725275 25.2959184,69.1538462" id="floors" />
                    <path d="M0,71.8791209 C5.62131519,71.1978022 9.83730159,70.8571429 12.6479592,70.8571429 C15.4586168,70.8571429 19.6746032,71.1978022 25.2959184,71.8791209" id="floors" />
                    <path d="M0,74.6043956 C5.62131519,73.9230769 9.83730159,73.5824176 12.6479592,73.5824176 C15.4586168,73.5824176 19.6746032,73.9230769 25.2959184,74.6043956" id="floors" />
                    <path d="M0,77.3296703 C5.62131519,76.6483516 9.83730159,76.3076923 12.6479592,76.3076923 C15.4586168,76.3076923 19.6746032,76.6483516 25.2959184,77.3296703" id="floors" />
                    <path d="M0,80.0549451 C5.62131519,79.3736264 9.83730159,79.032967 12.6479592,79.032967 C15.4586168,79.032967 19.6746032,79.3736264 25.2959184,80.0549451" id="floors" />
                    <path d="M0,82.7802198 C5.62131519,82.0989011 9.83730159,81.7582418 12.6479592,81.7582418 C15.4586168,81.7582418 19.6746032,82.0989011 25.2959184,82.7802198" id="floors" />
                    <path d="M0,85.5054945 C5.62131519,84.8241758 9.83730159,84.4835165 12.6479592,84.4835165 C15.4586168,84.4835165 19.6746032,84.8241758 25.2959184,85.5054945" id="floors" />
                    <path d="M0,88.2307692 C5.62131519,87.5494505 9.83730159,87.2087912 12.6479592,87.2087912 C15.4586168,87.2087912 19.6746032,87.5494505 25.2959184,88.2307692" id="floors" />
                    <path d="M0,90.956044 C5.62131519,90.2747253 9.83730159,89.9340659 12.6479592,89.9340659 C15.4586168,89.9340659 19.6746032,90.2747253 25.2959184,90.956044" id="floors" />
                    <path d="M0,93.6813187 C5.62131519,93 9.83730159,92.6593407 12.6479592,92.6593407 C15.4586168,92.6593407 19.6746032,93 25.2959184,93.6813187" id="floors" />
                    <path d="M0,96.4065934 C5.62131519,95.7252747 9.83730159,95.3846154 12.6479592,95.3846154 C15.4586168,95.3846154 19.6746032,95.7252747 25.2959184,96.4065934" id="floors" />
                    <path d="M0,99.1318681 C5.62131519,98.4505495 9.83730159,98.1098901 12.6479592,98.1098901 C15.4586168,98.1098901 19.6746032,98.4505495 25.2959184,99.1318681" id="floors" />
                    <path d="M0,101.857143 C5.62131519,101.175824 9.83730159,100.835165 12.6479592,100.835165 C15.4586168,100.835165 19.6746032,101.175824 25.2959184,101.857143" id="floors" />
                    <path d="M0,104.582418 C5.62131519,103.901099 9.83730159,103.56044 12.6479592,103.56044 C15.4586168,103.56044 19.6746032,103.901099 25.2959184,104.582418" id="floors" />
                  </g>
                  <path d="M21.4770408,14.356283 L21.4770408,122.693681 L48.2576531,122.693681 L48.2576531,14.356283 C42.4301067,15.2545411 37.9699789,15.7042519 34.8673469,15.7042519 C31.764715,15.7042519 27.3045872,15.2545411 21.4770408,14.356283 Z" id="building-right" stroke="#000000" strokeWidth="1.25" fill="#FFFFFF" />
                  <text id="sign" fontFamily="AndaleMono, Andale Mono" fontSize="5" fontWeight="normal" fill="#000000">
                    <tspan x="12.3061224" y="7.13186813">EXPO</tspan>
                  </text>
                  <g id="floors-right" transform="translate(22.219388, 18.395604)" fill="#D8D8D8" stroke="#000000" strokeLinecap="square" strokeWidth="0.5">
                    <path d="M0,1.64757097e-13 C5.07691732,0.820438077 9.29290372,1.27465053 12.6479592,1.36263736 C16.0030147,1.45062419 20.3329466,0.99641174 25.6377551,1.64757097e-13" id="floor" />
                    <path d="M0,2.72527473 C5.07691732,3.5457128 9.29290372,3.99992526 12.6479592,4.08791209 C16.0030147,4.17589892 20.3329466,3.72168647 25.6377551,2.72527473" id="floor" />
                    <path d="M0,5.45054945 C5.07691732,6.27098753 9.29290372,6.72519998 12.6479592,6.81318681 C16.0030147,6.90117364 20.3329466,6.44696119 25.6377551,5.45054945" id="floor" />
                    <path d="M0,8.17582418 C5.07691732,8.99626225 9.29290372,9.45047471 12.6479592,9.53846154 C16.0030147,9.62644837 20.3329466,9.17223592 25.6377551,8.17582418" id="floor" />
                    <path d="M0,10.9010989 C5.07691732,11.721537 9.29290372,12.1757494 12.6479592,12.2637363 C16.0030147,12.3517231 20.3329466,11.8975106 25.6377551,10.9010989" id="floor" />
                    <path d="M0,13.6263736 C5.07691732,14.4468117 9.29290372,14.9010242 12.6479592,14.989011 C16.0030147,15.0769978 20.3329466,14.6227854 25.6377551,13.6263736" id="floor" />
                    <path d="M0,16.3516484 C5.07691732,17.1720864 9.29290372,17.6262989 12.6479592,17.7142857 C16.0030147,17.8022725 20.3329466,17.3480601 25.6377551,16.3516484" id="floor" />
                    <path d="M0,19.0769231 C5.07691732,19.8973612 9.29290372,20.3515736 12.6479592,20.4395604 C16.0030147,20.5275473 20.3329466,20.0733348 25.6377551,19.0769231" id="floor" />
                    <path d="M0,21.8021978 C5.07691732,22.6226359 9.29290372,23.0768483 12.6479592,23.1648352 C16.0030147,23.252822 20.3329466,22.7986095 25.6377551,21.8021978" id="floor" />
                    <path d="M0,24.5274725 C5.07691732,25.3479106 9.29290372,25.8021231 12.6479592,25.8901099 C16.0030147,25.9780967 20.3329466,25.5238843 25.6377551,24.5274725" id="floor" />
                    <path d="M0,27.2527473 C5.07691732,28.0731853 9.29290372,28.5273978 12.6479592,28.6153846 C16.0030147,28.7033714 20.3329466,28.249159 25.6377551,27.2527473" id="floor" />
                    <path d="M0,29.978022 C5.07691732,30.7984601 9.29290372,31.2526725 12.6479592,31.3406593 C16.0030147,31.4286462 20.3329466,30.9744337 25.6377551,29.978022" id="floor" />
                    <path d="M0,32.7032967 C5.07691732,33.5237348 9.29290372,33.9779472 12.6479592,34.0659341 C16.0030147,34.1539209 20.3329466,33.6997084 25.6377551,32.7032967" id="floor" />
                    <path d="M0,35.4285714 C5.07691732,36.2490095 9.29290372,36.703222 12.6479592,36.7912088 C16.0030147,36.8791956 20.3329466,36.4249832 25.6377551,35.4285714" id="floor" />
                    <path d="M0,38.1538462 C5.07691732,38.9742842 9.29290372,39.4284967 12.6479592,39.5164835 C16.0030147,39.6044703 20.3329466,39.1502579 25.6377551,38.1538462" id="floor" />
                    <path d="M0,40.8791209 C5.07691732,41.699559 9.29290372,42.1537714 12.6479592,42.2417582 C16.0030147,42.3297451 20.3329466,41.8755326 25.6377551,40.8791209" id="floor" />
                    <path d="M0,43.6043956 C5.07691732,44.4248337 9.29290372,44.8790461 12.6479592,44.967033 C16.0030147,45.0550198 20.3329466,44.6008073 25.6377551,43.6043956" id="floor" />
                    <path d="M0,46.3296703 C5.07691732,47.1501084 9.29290372,47.6043209 12.6479592,47.6923077 C16.0030147,47.7802945 20.3329466,47.3260821 25.6377551,46.3296703" id="floor" />
                    <path d="M0,49.0549451 C5.07691732,49.8753831 9.29290372,50.3295956 12.6479592,50.4175824 C16.0030147,50.5055692 20.3329466,50.0513568 25.6377551,49.0549451" id="floor" />
                    <path d="M0,51.7802198 C5.07691732,52.6006579 9.29290372,53.0548703 12.6479592,53.1428571 C16.0030147,53.230844 20.3329466,52.7766315 25.6377551,51.7802198" id="floor" />
                    <path d="M0,54.5054945 C5.07691732,55.3259326 9.29290372,55.780145 12.6479592,55.8681319 C16.0030147,55.9561187 20.3329466,55.5019062 25.6377551,54.5054945" id="floor" />
                    <path d="M0,57.2307692 C5.07691732,58.0512073 9.29290372,58.5054198 12.6479592,58.5934066 C16.0030147,58.6813934 20.3329466,58.227181 25.6377551,57.2307692" id="floor" />
                    <path d="M0,59.956044 C5.07691732,60.776482 9.29290372,61.2306945 12.6479592,61.3186813 C16.0030147,61.4066682 20.3329466,60.9524557 25.6377551,59.956044" id="floor" />
                    <path d="M0,62.6813187 C5.07691732,63.5017568 9.29290372,63.9559692 12.6479592,64.043956 C16.0030147,64.1319429 20.3329466,63.6777304 25.6377551,62.6813187" id="floor" />
                    <path d="M0,59.956044 C5.07691732,60.776482 9.29290372,61.2306945 12.6479592,61.3186813 C16.0030147,61.4066682 20.3329466,60.9524557 25.6377551,59.956044" id="floor" />
                    <path d="M0,62.6813187 C5.07691732,63.5017568 9.29290372,63.9559692 12.6479592,64.043956 C16.0030147,64.1319429 20.3329466,63.6777304 25.6377551,62.6813187" id="floor" />
                    <path d="M0,65.4065934 C5.07691732,66.2270315 9.29290372,66.6812439 12.6479592,66.7692308 C16.0030147,66.8572176 20.3329466,66.4030051 25.6377551,65.4065934" id="floor" />
                    <path d="M0,68.1318681 C5.07691732,68.9523062 9.29290372,69.4065187 12.6479592,69.4945055 C16.0030147,69.5824923 20.3329466,69.1282799 25.6377551,68.1318681" id="floor" />
                    <path d="M0,70.8571429 C5.07691732,71.6775809 9.29290372,72.1317934 12.6479592,72.2197802 C16.0030147,72.3077671 20.3329466,71.8535546 25.6377551,70.8571429" id="floor" />
                    <path d="M0,73.5824176 C5.07691732,74.4028557 9.29290372,74.8570681 12.6479592,74.9450549 C16.0030147,75.0330418 20.3329466,74.5788293 25.6377551,73.5824176" id="floor" />
                    <path d="M0,76.3076923 C5.07691732,77.1281304 9.29290372,77.5823428 12.6479592,77.6703297 C16.0030147,77.7583165 20.3329466,77.304104 25.6377551,76.3076923" id="floor" />
                    <path d="M0,79.032967 C5.07691732,79.8534051 9.29290372,80.3076176 12.6479592,80.3956044 C16.0030147,80.4835912 20.3329466,80.0293788 25.6377551,79.032967" id="floor" />
                    <path d="M0,81.7582418 C5.07691732,82.5786798 9.29290372,83.0328923 12.6479592,83.1208791 C16.0030147,83.208866 20.3329466,82.7546535 25.6377551,81.7582418" id="floor" />
                    <path d="M0,84.4835165 C5.07691732,85.3039546 9.29290372,85.758167 12.6479592,85.8461538 C16.0030147,85.9341407 20.3329466,85.4799282 25.6377551,84.4835165" id="floor" />
                    <path d="M0,87.2087912 C5.07691732,88.0292293 9.29290372,88.4834417 12.6479592,88.5714286 C16.0030147,88.6594154 20.3329466,88.2052029 25.6377551,87.2087912" id="floor" />
                    <path d="M0,89.9340659 C5.07691732,90.754504 9.29290372,91.2087165 12.6479592,91.2967033 C16.0030147,91.3846901 20.3329466,90.9304777 25.6377551,89.9340659" id="floor" />
                    <path d="M0,92.6593407 C5.07691732,93.4797787 9.29290372,93.9339912 12.6479592,94.021978 C16.0030147,94.1099649 20.3329466,93.6557524 25.6377551,92.6593407" id="floor" />
                  </g>
                  <path d="M0.966836735,96.7849841 L0.966836735,122.94687 L66.375,122.94687 L66.375,96.5849129 C58.7753463,95.1722708 53.6542883,94.5921144 51.0636829,94.83896 C48.3961537,95.0931353 45.4431833,95.5269021 40.4363826,96.3545945 C40.1498552,96.4019613 39.9630924,96.4329174 39.4900472,96.5114249 C30.6382618,97.9810648 27.7052678,98.3942308 24.9540816,98.3942308 C16.7157164,98.3942308 8.71991547,97.8578623 0.966836725,96.7849839 Z" id="base" stroke="#000000" strokeWidth="1.25" fill="#FFFFFF" />
                </g>
                <g id="arco-della-pace" transform="translate(389.000000, 107.344369)">
                  <path d="M47.9203774,4.8 L2.36037736,4.8 L2.36037736,47.901882 L7.30689164,47.901882 C7.30689164,39.5050709 7.39367259,34.2326547 7.5672345,32.0846332 C7.73627242,29.9926011 9.56889618,29.7413371 10.1706631,29.7413371 C10.7459106,29.7413371 12.232101,30.1777364 12.3431579,32.0846332 C12.4568852,34.03738 12.5137488,39.3425022 12.5137488,48 L17.7206059,47.901882 C17.7206059,38.9192469 17.7206059,32.9633693 17.7206059,30.0342491 C17.7206059,24.718487 22.1718441,22.4185367 25.2091011,22.4185367 C28.3263959,22.4185367 32.8204916,24.7437499 32.8204916,30.3271611 C32.8204916,33.0610066 32.8204916,38.9519529 32.8204916,48 L38.0273488,47.901882 C38.0273488,39.3097962 38.0273488,34.03738 38.0273488,32.0846332 C38.0273488,30.6090294 39.1988916,29.7413371 40.3704345,29.7413371 C41.5419774,29.7413371 42.7135202,30.3504591 42.7135202,32.0846332 C42.7135202,34.03738 42.7135202,39.3097962 42.7135202,47.901882 L47.9203774,48 L47.9203774,4.8 Z" id="building" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <path d="M2.10537736,18.1714286 L0.320377356,18.1714286" id="line" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <path d="M50.2650041,18.1714286 L48.6003774,18.1714286" id="line" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <path d="M50.2650041,14.7428571 L0.320377356,14.7428571" id="line" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <text id="year" fontFamily="STHeitiTC-Medium, Heiti TC" fontSize="4" fontWeight="bold" fill="#000000">
                    <tspan x="14" y="12">MDCCCLIX</tspan>
                  </text>
                  <path d="M10.5203774,4.8 L18.2383373,1.47130096 C18.8817653,1.19379524 19.6289441,1.48227138 19.9189488,2.12016341 L19.9877857,2.27157646 C20.1634014,2.65785959 20.6189105,2.82863922 21.0051937,2.65302343 C21.0863852,2.61611134 21.1605299,2.56534448 21.2243059,2.50299721 L21.2243059,2.50299721 C21.6906363,2.04711425 22.398551,1.94584071 22.9739968,2.25268825 L23.4434131,2.50299721 C24.3452512,2.9838884 25.423061,3.00287826 26.3412797,2.55405453 L27.0281319,2.21832238 C27.6181383,1.92992824 28.3257769,2.04391744 28.7953774,2.50299721 L28.7953774,2.50299721 C29.1288406,2.82898977 29.6634352,2.82293355 29.9894278,2.48947027 C30.0401216,2.43761474 30.083924,2.3794447 30.1197536,2.31639633 L30.2312702,2.12016341 C30.602739,1.46649948 31.406573,1.19706598 32.0969424,1.49481717 L39.7603774,4.8" id="art-middle" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <path d="M43.4374646,3.3411547 L43.7986783,2.79057278 C44.0012328,2.481828 44.4157227,2.39574377 44.7244675,2.59829827 C44.8119492,2.65569136 44.8847405,2.73281437 44.9369865,2.82346411 L44.9369865,2.82346411 C45.1202669,3.141466 45.5266366,3.25067931 45.8446385,3.06739885 C45.9357895,3.01486392 46.0131659,2.94143923 46.0704025,2.85316469 L46.6998993,1.88230941 C46.8993009,1.57477775 47.3102516,1.48712108 47.6177833,1.68652275 C47.8064817,1.80887371 47.9203774,2.01846505 47.9203774,2.24335808 L47.9203774,4.8 L44.225154,4.8 C43.7048602,4.8 43.2830787,4.37821852 43.2830787,3.8579247 C43.2830787,3.67430331 43.3367401,3.4946845 43.4374646,3.3411547 Z" id="art-right" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" />
                  <path d="M3.31746456,3.3411547 L3.67867827,2.79057278 C3.88123277,2.481828 4.29572273,2.39574377 4.60446751,2.59829827 C4.69194923,2.65569136 4.76474048,2.73281437 4.81698648,2.82346411 L4.81698648,2.82346411 C5.00026693,3.141466 5.40663657,3.25067931 5.72463846,3.06739885 C5.81578951,3.01486392 5.89316587,2.94143923 5.95040255,2.85316469 L6.57989927,1.88230941 C6.77930094,1.57477775 7.19025161,1.48712108 7.49778326,1.68652275 C7.68648174,1.80887371 7.80037736,2.01846505 7.80037736,2.24335808 L7.80037736,4.8 L4.105154,4.8 C3.58486017,4.8 3.1630787,4.37821852 3.1630787,3.8579247 C3.1630787,3.67430331 3.2167401,3.4946845 3.31746456,3.3411547 Z" id="art-left" stroke="#000000" strokeWidth="1.25" strokeLinecap="square" transform="translate(5.080377, 2.400000) scale(-1, 1) translate(-5.080377, -2.400000) " />
                </g>
                <g id="torre" transform="translate(360.000000, 81.344369)" stroke="#000000">
                  <g id="building" transform="translate(0.333333, 4.073394)" strokeLinecap="square" strokeWidth="1.25">
                    <path d="M1.59872116e-14,69.587156 L1.59872116e-14,7.78899083 C1.59195762e-14,7.23670608 0.44771525,6.78899083 1,6.78899083 L17.6804166,6.78899083 C18.2327014,6.78899083 18.6804166,7.23670608 18.6804166,7.78899083 L18.6804166,69.587156" />
                    <polyline id="canopy" points="0.333333333 6.78899083 9.33333333 0 18.3333333 6.78899083" />
                  </g>
                  <g id="windows" transform="translate(2.000000, 13.577982)" strokeWidth="1.25">
                    <rect id="window" x="0.625" y="0.625" width="3.41666667" height="2.8233945" />
                    <rect id="window" x="5.95833333" y="0.625" width="3.41666667" height="2.8233945" />
                    <rect id="window" x="11.2916667" y="0.625" width="3.41666667" height="2.8233945" />
                  </g>
                  <g id="spire" transform="translate(8.666667, 0.000000)">
                    <path d="M1.00687497,4.0733945 L1.00687497,1.69724771" id="line" strokeWidth="2" strokeLinecap="square" />
                    <ellipse id="circle" strokeWidth="1.25" cx="1" cy="1.01834862" rx="1" ry="1.01834862" />
                  </g>
                  <g id="facade" transform="translate(4.000000, 23.082569)" strokeLinecap="square" strokeWidth="1.25">
                    <path d="M0.333333333,0 L0.333333333,50.2385321" id="line" />
                    <path d="M11,0 L11,50.2385321" id="line" />
                    <path d="M3,0 L3,50.2385321" id="line" />
                    <path d="M8.33333333,0 L8.33333333,50.2385321" id="line" />
                    <path d="M5.66666667,0 L5.66666667,50.2385321" id="line" />
                  </g>
                </g>
                <g id="duomo" transform="translate(113.000000, 75.344369)" stroke="#000000" strokeLinecap="square" strokeWidth="1.25">
                  <g id="lines" transform="translate(8.191781, 18.303557)">
                    <path d="M-3.10862447e-14,21.7139116 L8.19178082,21.7139116" id="line" />
                    <path d="M66.7945205,21.7139116 L74.9863014,21.7139116" id="line" />
                    <path d="M12.6027397,9.7555255 L20.7945205,9.7555255" id="line" />
                    <path d="M54.8219178,9.7555255 L63.0136986,9.7555255" id="line" />
                    <path d="M28.9863014,0.314694371 L46.630137,0.314694371" id="line" />
                  </g>
                  <path d="M0,80.2983481 L92,80.2983481" id="base" />
                  <polyline id="canopy" points="7.56164384 34.0382756 46 10.1215034 84.4383562 34.0382756" />
                  <g id="spires" transform="translate(0.000000, 0.629389)">
                    <path d="M0.272328112,23.5761343 L-2.28705943e-14,25.3141562 L-2.28705943e-14,79.354265 L2.52054795,79.354265 L2.52054795,25.3141562 L2.24821983,23.5761343 C2.16272627,23.0305069 1.65110161,22.6574947 1.10547418,22.7429883 C0.676264774,22.8102405 0.339580307,23.1469249 0.272328112,23.5761343 Z" fill="#FFFFFF" />
                    <path d="M5.313424,23.5761343 L5.04109589,25.3141562 L5.04109589,79.354265 L7.56164384,79.354265 L7.56164384,25.3141562 L7.28931572,23.5761343 C7.20382216,23.0305069 6.6921975,22.6574947 6.14657007,22.7429883 C5.71736066,22.8102405 5.3806762,23.1469249 5.313424,23.5761343 Z" fill="#FFFFFF" />
                    <path d="M84.7106843,23.5761343 L84.4383562,25.3141562 L84.4383562,79.354265 L86.9589041,79.354265 L86.9589041,25.3141562 L86.686576,23.5761343 C86.6010824,23.0305069 86.0894578,22.6574947 85.5438303,22.7429883 C85.1146209,22.8102405 84.7779365,23.1469249 84.7106843,23.5761343 Z" fill="#FFFFFF" />
                    <path d="M89.7517802,23.5761343 L89.4794521,25.3141562 L89.4794521,79.354265 L92,79.354265 L92,25.3141562 L91.7276719,23.5761343 C91.6421783,23.0305069 91.1305537,22.6574947 90.5849262,22.7429883 C90.1557168,22.8102405 89.8190324,23.1469249 89.7517802,23.5761343 Z" fill="#FFFFFF" />
                    <path d="M17.9127703,13.4057478 L17.6438356,15.4358568 L17.6438356,79.354265 L20.1643836,79.354265 L20.1643836,15.4358568 L19.8954489,13.4057478 C19.8229197,12.8582463 19.3202854,12.4732051 18.7727839,12.5457343 C18.3246924,12.6050943 17.9721304,12.9576564 17.9127703,13.4057478 Z" fill="#FFFFFF" />
                    <path d="M72.7346881,13.4057478 L72.4657534,15.4358568 L72.4657534,79.354265 L74.9863014,79.354265 L74.9863014,15.4358568 L74.7173667,13.4057478 C74.6448375,12.8582463 74.1422032,12.4732051 73.5947017,12.5457343 C73.1466102,12.6050943 72.7940482,12.9576564 72.7346881,13.4057478 Z" fill="#FFFFFF" />
                    <path d="M29.8833336,3.77958757 L29.6164384,6.08735023 L29.6164384,79.3029815 L32.1369863,79.3029815 L32.1369863,6.08735023 L31.8700911,3.77958757 C31.8066417,3.23095962 31.310455,2.83764471 30.761827,2.90109412 C30.3005468,2.95444166 29.9366811,3.31830737 29.8833336,3.77958757 Z" fill="#FFFFFF" />
                    <path d="M34.9244295,3.77958757 L34.6575342,6.08735023 L34.6575342,79.3029815 L37.1780822,79.3029815 L37.1780822,6.08735023 L36.911187,3.77958757 C36.8477376,3.23095962 36.3515508,2.83764471 35.8029229,2.90109412 C35.3416427,2.95444166 34.977777,3.31830737 34.9244295,3.77958757 Z" fill="#FFFFFF" />
                    <path d="M55.088813,3.83087109 L54.8219178,6.13863375 L54.8219178,79.354265 L57.3424658,79.354265 L57.3424658,6.13863375 L57.0755705,3.83087109 C57.0121211,3.28224315 56.5159344,2.88892824 55.9673065,2.95237764 C55.5060263,3.00572519 55.1421606,3.3695909 55.088813,3.83087109 Z" fill="#FFFFFF" />
                    <path d="M60.1299089,3.83087109 L59.8630137,6.13863375 L59.8630137,79.354265 L62.3835616,79.354265 L62.3835616,6.13863375 L62.1166664,3.83087109 C62.053217,3.28224315 61.5570303,2.88892824 61.0084024,2.95237764 C60.5471222,3.00572519 60.1832565,3.3695909 60.1299089,3.83087109 Z" fill="#FFFFFF" />
                    <g id="floors" transform="translate(0.000000, 8.862726)">
                      <path d="M0.315068493,19.1963566 L2.20547945,19.1963566" id="line" />
                      <path d="M5.35616438,19.1963566 L7.24657534,19.1963566" id="line" />
                      <path d="M84.7534247,19.1963566 L86.6438356,19.1963566" id="line" />
                      <path d="M89.7945205,19.1963566 L91.6849315,19.1963566" id="line" />
                      <path d="M55.1369863,0.314694371 L57.0273973,0.314694371" id="line" />
                      <path d="M60.1780822,0.314694371 L62.0684932,0.314694371" id="line" />
                      <path d="M29.9315068,0.314694371 L31.8219178,0.314694371" id="line" />
                      <path d="M34.9726027,0.314694371 L36.8630137,0.314694371" id="line" />
                      <path d="M17.9589041,9.7555255 L19.8493151,9.7555255" id="line" />
                      <path d="M72.7808219,9.7555255 L74.6712329,9.7555255" id="line" />
                    </g>
                  </g>
                  <g id="main-door" transform="translate(40.328767, 61.101991)">
                    <path d="M-1.0658141e-14,18.2522735 L-1.0658141e-14,1.21681823 C2.52054795,0.405606078 4.4109589,0 5.67123288,0 C6.93150685,0 8.82191781,0.405606078 11.3424658,1.21681823 L11.3424658,18.2522735" />
                    <path d="M-1.0658141e-14,4.72041556 L11.3424658,4.72041556" id="line" />
                  </g>
                  <g id="windows-bottom" transform="translate(11.027397, 68.654656)">
                    <polyline id="window" points="12.2876712 10.6996086 12.2876712 -2.11306735e-13 15.4383562 -2.13162821e-13 15.4383562 10.6996086" />
                    <polyline id="window" points="1.23761985e-14 10.6996086 1.24344979e-14 -2.11306735e-13 3.15068493 -2.13162821e-13 3.15068493 10.6996086" />
                    <polyline id="window" points="54.8219178 10.6996086 54.8219178 -2.11306735e-13 57.9726027 -2.13162821e-13 57.9726027 10.6996086" />
                    <polyline id="window" points="67.109589 10.6996086 67.109589 -2.11306735e-13 70.260274 -2.13162821e-13 70.260274 10.6996086" />
                  </g>
                  <g id="windows-middle" transform="translate(11.027397, 49.772994)">
                    <path d="M1.24344979e-14,11.9583861 L1.24344979e-14,0.79722574 C0.700152207,0.265741913 1.22526636,-3.55271368e-14 1.57534247,-3.55271368e-14 C1.92541857,-3.55271368e-14 2.45053272,0.265741913 3.15068493,0.79722574 L3.15068493,11.9583861 L1.24344979e-14,11.9583861 Z" id="window" />
                    <path d="M12.2876712,11.9583861 L12.2876712,0.79722574 C12.9878234,0.265741913 13.5129376,-3.55271368e-14 13.8630137,-3.55271368e-14 C14.2130898,-3.55271368e-14 14.738204,0.265741913 15.4383562,0.79722574 L15.4383562,11.9583861 L12.2876712,11.9583861 Z" id="window" />
                    <path d="M54.8219178,11.9583861 L54.8219178,0.79722574 C55.52207,0.265741913 56.0471842,-3.55271368e-14 56.3972603,-3.55271368e-14 C56.7473364,-3.55271368e-14 57.2724505,0.265741913 57.9726027,0.79722574 L57.9726027,11.9583861 L54.8219178,11.9583861 Z" id="window" />
                    <path d="M67.109589,11.9583861 L67.109589,0.79722574 C67.8097412,0.265741913 68.3348554,-3.55271368e-14 68.6849315,-3.55271368e-14 C69.0350076,-3.55271368e-14 69.5601218,0.265741913 70.260274,0.79722574 L70.260274,11.9583861 L67.109589,11.9583861 Z" id="window" />
                  </g>
                  <g id="windows-top" transform="translate(23.315068, 22.709278)">
                    <path d="M18.9041096,32.0988258 L18.9041096,21.3093045 C20.5844749,20.1104688 21.8447489,19.511051 22.6849315,19.511051 C23.5251142,19.511051 24.7853881,20.1104688 26.4657534,21.3093045 L26.4657534,32.0988258 L18.9041096,32.0988258 Z" id="window" />
                    <path d="M18.9041096,14.4759411 L18.9041096,2.31615057 C20.4444444,0.77205019 21.5996956,-1.17239551e-13 22.369863,-1.17239551e-13 C23.1400304,-1.17239551e-13 24.2952816,0.77205019 25.8356164,2.31615057 L25.8356164,14.4759411 L18.9041096,14.4759411 Z" id="window" />
                    <path d="M-9.9475983e-14,22.6579947 L-9.9475983e-14,11.4968344 C0.700152207,10.9653505 1.22526636,10.6996086 1.57534247,10.6996086 C1.92541857,10.6996086 2.45053272,10.9653505 3.15068493,11.4968344 L3.15068493,22.6579947 L-9.9475983e-14,22.6579947 Z" id="window" />
                    <path d="M42.5342466,22.6579947 L42.5342466,11.4968344 C43.2343988,10.9653505 43.7595129,10.6996086 44.109589,10.6996086 C44.4596651,10.6996086 44.9847793,10.9653505 45.6849315,11.4968344 L45.6849315,22.6579947 L42.5342466,22.6579947 Z" id="window" />
                    <path d="M18.9041096,29.8959652 L26.4657534,29.8959652" id="line" />
                  </g>
                </g>
                <g id="hills" transform="translate(274.000000, 25.000000)" stroke="#000000" strokeLinecap="square" strokeWidth="1.25">
                  <path d="M0,70.3443686 C24.6072278,36.3443686 43.6072278,19.3443686 57,19.3443686 C70.3927722,19.3443686 89.3927722,36.3443686 114,70.3443686" id="hill" />
                  <path d="M30,39.6126878 C32.2234396,41.2828852 34.7394825,42.1071905 37.5481286,42.0856036 C41.7610978,42.0532232 41.7027994,39.4189579 46.4120269,39.4865468 C51.1212545,39.5541357 50.9311005,42.3803756 55.5532452,42.3440209 C60.1753899,42.3076661 60.1909996,39.3576384 64.4828271,39.3444138 C68.7746547,39.3311892 68.9508964,42.2266822 72.8868258,42.2906101 C75.5107788,42.3332287 78.2151702,41.4405879 81,39.6126878" id="snow-line" />
                  <path d="M80,30.39794 C94.5323776,10.3622257 108.504705,0.344368601 121.916983,0.344368601 C135.329261,0.344368601 154.356933,17.3443686 179,51.3443686" id="hill" />
                  <path d="M90,26.7021275 C92.7466019,28.9290574 95.8546548,30.0281311 99.3241589,29.9993486 C104.528415,29.9561747 104.456399,26.443821 110.27368,26.5339395 C116.090961,26.6240581 115.856065,30.392378 121.565773,30.343905 C127.275482,30.295432 127.294764,26.3620616 132.596434,26.3444288 C137.898103,26.326796 138.115813,30.1874535 142.977844,30.2726906 C146.219197,30.3295154 149.559916,29.1393277 153,26.7021275" id="snow-line" />
                </g>
                <g id="unicredit-tower" transform="translate(51.000000, 0.344369)" stroke="#000000">
                  <g id="spire" transform="translate(30.992754, 0.000000)" strokeWidth="1.25">
                    <polyline id="middle" points="6.47101449 50.6363898 6.47101449 26.1627055 2.38405797 31.601302 2.38405797 48.5969161" />
                    <polyline id="base" strokeLinecap="round" strokeLinejoin="round" points="5.32907052e-15 64.232881 2.32897437e-14 47.5771792 8.51449275 51.3162143 8.51449275 64.232881" />
                    <polyline id="top" strokeLinecap="square" points="3.4057971 29.9017406 4.42845448 1.17239551e-13 5.75981166 1.16255648 5.75981166 28.1946062" />
                  </g>
                  <polyline id="building-right" strokeWidth="1.25" strokeLinecap="square" points="47 154.649548 43.2536232 64.5727933 29.2898551 64.5727933 29.6304348 154.649548" />
                  <path d="M0,154.649548 L-5.20292344e-14,79.8688459 C10.0248632,77.9030013 16.3823511,76.316744 19.0724638,75.110074 C23.3106588,73.2090001 26.7164559,70.2630936 29.2898551,66.2723547" id="building-left" strokeWidth="1.25" strokeLinecap="round" />
                  <g id="floors-right" transform="translate(32.695652, 68.991653)" strokeLinecap="square" strokeWidth="0.5">
                    <path d="M0,0.339912281 L6.13043478,0.339912281" id="floors" />
                    <path d="M0,2.37938596 L6.13043478,2.37938596" id="floors" />
                    <path d="M0,4.41885965 L6.13043478,4.41885965" id="floors" />
                    <path d="M0,6.45833333 L6.13043478,6.45833333" id="floors" />
                    <path d="M0,8.49780702 L6.13043478,8.49780702" id="floors" />
                    <path d="M0,10.5372807 L6.13043478,10.5372807" id="floors" />
                    <path d="M0,12.5767544 L6.13043478,12.5767544" id="floors" />
                    <path d="M0,14.6162281 L6.13043478,14.6162281" id="floors" />
                    <path d="M0,16.6557018 L6.13043478,16.6557018" id="floors" />
                    <path d="M0,18.6951754 L6.13043478,18.6951754" id="floors" />
                    <path d="M0,20.7346491 L6.13043478,20.7346491" id="floors" />
                    <path d="M0,22.7741228 L6.13043478,22.7741228" id="floors" />
                    <path d="M0,24.8135965 L6.13043478,24.8135965" id="floors" />
                    <path d="M0,26.8530702 L6.13043478,26.8530702" id="floors" />
                    <path d="M0,28.8925439 L6.13043478,28.8925439" id="floors" />
                    <path d="M0,30.9320175 L6.13043478,30.9320175" id="floors" />
                    <path d="M0,32.9714912 L6.13043478,32.9714912" id="floors" />
                    <path d="M0,35.0109649 L6.13043478,35.0109649" id="floors" />
                    <path d="M0,37.0504386 L6.13043478,37.0504386" id="floors" />
                    <path d="M0,39.0899123 L6.13043478,39.0899123" id="floors" />
                    <path d="M0,41.129386 L6.13043478,41.129386" id="floors" />
                    <path d="M0,43.1688596 L6.13043478,43.1688596" id="floors" />
                    <path d="M0,45.2083333 L6.13043478,45.2083333" id="floors" />
                    <path d="M0,47.247807 L6.13043478,47.247807" id="floors" />
                    <path d="M0,49.2872807 L6.13043478,49.2872807" id="floors" />
                    <path d="M0,51.3267544 L6.13043478,51.3267544" id="floors" />
                    <path d="M0,53.3662281 L6.13043478,53.3662281" id="floors" />
                    <path d="M0,55.4057018 L6.13043478,55.4057018" id="floors" />
                    <path d="M0,57.4451754 L6.13043478,57.4451754" id="floors" />
                    <path d="M0,59.4846491 L6.13043478,59.4846491" id="floors" />
                    <path d="M0,61.5241228 L6.13043478,61.5241228" id="floors" />
                    <path d="M0,63.5635965 L6.13043478,63.5635965" id="floors" />
                    <path d="M0,65.6030702 L6.13043478,65.6030702" id="floors" />
                    <path d="M0,67.6425439 L6.13043478,67.6425439" id="floors" />
                    <path d="M0,69.6820175 L6.13043478,69.6820175" id="floors" />
                    <path d="M0,71.7214912 L6.13043478,71.7214912" id="floors" />
                    <path d="M0,73.7609649 L6.13043478,73.7609649" id="floors" />
                    <path d="M0,75.8004386 L6.13043478,75.8004386" id="floors" />
                    <path d="M0,77.8399123 L6.13043478,77.8399123" id="floors" />
                    <path d="M0,79.879386 L6.13043478,79.879386" id="floors" />
                    <path d="M0,81.9188596 L6.13043478,81.9188596" id="floors" />
                  </g>
                  <g id="floors-left" transform="translate(4.086957, 83.947793)" strokeLinecap="square" strokeWidth="1.25">
                    <path d="M0.331617086,0.339912281 L25.2028986,0.339912281" id="floors" />
                    <path d="M0.331617086,8.49780702 L25.2028986,8.49780702" id="floors" />
                    <path d="M0.331617086,4.41885965 L25.2028986,4.41885965" id="floors" />
                    <path d="M0.331617086,12.5767544 L25.2028986,12.5767544" id="floors" />
                    <path d="M0.331617086,16.6557018 L25.2028986,16.6557018" id="floors" />
                    <path d="M0.331617086,20.7346491 L25.2028986,20.7346491" id="floors" />
                    <path d="M0.331617086,24.8135965 L25.2028986,24.8135965" id="floors" />
                    <path d="M0.331617086,28.8925439 L25.2028986,28.8925439" id="floors" />
                    <path d="M0.331617086,37.0504386 L25.2028986,37.0504386" id="floors" />
                    <path d="M0.331617086,32.9714912 L25.2028986,32.9714912" id="floors" />
                    <path d="M0.331617086,41.129386 L25.2028986,41.129386" id="floors" />
                    <path d="M0.331617086,49.2872807 L25.2028986,49.2872807" id="floors" />
                    <path d="M0.331617086,45.2083333 L25.2028986,45.2083333" id="floors" />
                    <path d="M0.331617086,53.3662281 L25.2028986,53.3662281" id="floors" />
                    <path d="M0.331617086,57.4451754 L25.2028986,57.4451754" id="floors" />
                    <path d="M0.331617086,61.5241228 L25.2028986,61.5241228" id="floors" />
                    <path d="M0.331617086,65.6030702 L25.2028986,65.6030702" id="floors" />
                  </g>
                </g>
                <path d="M540,155.5 L0,155.5" id="road" stroke="#000000" strokeWidth="2" strokeLinecap="square" />

                <g id="treeline" transform="translate(4.000000, 102.000000)">
                  <g id="tree-big" transform="translate(469.000000, 0.000000)" stroke="#000000">
                    <path d="M12.5,52 C12.5,49.6034149 12.5,48.4051223 12.5,48.4051223 C12.5,48.4051223 12.5,41.6034149 12.5,28" id="Line-5" strokeWidth="2" strokeLinecap="square" />
                    <path d="M12.5,48 C19.4035594,48 25,42.2315339 25,35.2494716 C25,35.2494716 25,35.2494716 25,35.2494716 L25,35.2494716 C25,28.3498888 19.406783,22.7566719 12.5072002,22.7566719 C6.19957176,22.7566719 0.881409535,27.45863 0.108536046,33.7187293 C0.0362090619,34.3053109 0,34.8155546 0,35.2494716 C0,42.2315339 5.59644063,48 12.5,48 Z" id="Oval" strokeWidth="1.5" fill="#03EBB0" />
                  </g>
                  <g id="tree-big" transform="translate(272.000000, 1.000000)" stroke="#000000">
                    <path d="M11.5,51 C11.5,48.6034149 11.5,47.4051223 11.5,47.4051223 C11.5,47.4051223 11.5,40.6034149 11.5,27" id="Line-5" strokeWidth="2" strokeLinecap="square" />
                    <path d="M12,46.1538462 C18.627417,46.1538462 24,40.8291082 24,34.3841277 C24,34.3841277 24,34.3841277 24,34.3841277 L24,34.3841277 C24,27.7628541 18.6323972,22.3952513 12.0111237,22.3952513 C6.05600193,22.3952513 1.00354469,26.766396 0.14692917,32.6595857 C0.0489964334,33.3337481 0,33.9085926 0,34.3841277 C0,40.8291082 5.372583,46.1538462 12,46.1538462 Z" id="Oval" strokeWidth="1.5" fill="#03EBB0" />
                  </g>
                  <g id="tree-big-copy" transform="translate(79.000000, 1.000000)" stroke="#000000">
                    <path d="M11.5,51 C11.5,48.6034149 11.5,47.4051223 11.5,47.4051223 C11.5,47.4051223 11.5,40.6034149 11.5,27" id="Line-5" strokeWidth="2" strokeLinecap="square" />
                    <path d="M12,46.1538462 C18.627417,46.1538462 24,40.8291082 24,34.3841277 C24,34.3841277 24,34.3841277 24,34.3841277 L24,34.3841277 C24,27.7628541 18.6323972,22.3952513 12.0111237,22.3952513 C6.05600193,22.3952513 1.00354469,26.766396 0.14692917,32.6595857 C0.0489964334,33.3337481 0,33.9085926 0,34.3841277 C0,40.8291082 5.372583,46.1538462 12,46.1538462 Z" id="Oval" strokeWidth="1.5" fill="#03EBB0" />
                  </g>
                  <g id="trees" transform="translate(374.000000, 17.000000)">
                    <g id="big" transform="translate(7.000000, 4.000000)">
                      <rect id="trunk" fill="#000000" x="6.3" y="17.7021277" width="1.4" height="14.2978723" />
                      <path d="M7,26.5531915 C10.8659932,26.5531915 14,23.5049174 14,19.7446809 C14,15.9844443 10.8659932,-1.42108547e-14 7,-1.42108547e-14 C3.13400675,-1.42108547e-14 0,15.9844443 0,19.7446809 C0,23.5049174 3.13400675,26.5531915 7,26.5531915 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#03EBB0" />
                    </g>
                    <g id="small">
                      <rect id="trunk" fill="#000000" x="6.3" y="21.7358491" width="1.4" height="14.2641509" />
                      <path d="M7,31.245283 C10.8659932,31.245283 14,28.2041983 14,24.4528302 C14,20.7014621 10.8659932,-2.48689958e-14 7,-2.48689958e-14 C3.13400675,-2.48689958e-14 -1.42108547e-14,20.7014621 -1.42108547e-14,24.4528302 C-1.42108547e-14,28.2041983 3.13400675,31.245283 7,31.245283 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#03EBB0" />
                    </g>
                  </g>
                  <g id="trees" transform="translate(208.000000, 17.000000)">
                    <g id="big" transform="translate(7.000000, 4.000000)">
                      <rect id="trunk" fill="#000000" x="6.3" y="17.7021277" width="1.4" height="14.2978723" />
                      <path d="M7,26.5531915 C10.8659932,26.5531915 14,23.5049174 14,19.7446809 C14,15.9844443 10.8659932,-1.42108547e-14 7,-1.42108547e-14 C3.13400675,-1.42108547e-14 0,15.9844443 0,19.7446809 C0,23.5049174 3.13400675,26.5531915 7,26.5531915 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#03EBB0" />
                    </g>
                    <g id="small">
                      <rect id="trunk" fill="#000000" x="6.3" y="21.7358491" width="1.4" height="14.2641509" />
                      <path d="M7,31.245283 C10.8659932,31.245283 14,28.2041983 14,24.4528302 C14,20.7014621 10.8659932,-2.48689958e-14 7,-2.48689958e-14 C3.13400675,-2.48689958e-14 -1.42108547e-14,20.7014621 -1.42108547e-14,24.4528302 C-1.42108547e-14,28.2041983 3.13400675,31.245283 7,31.245283 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#00FFBF" />
                    </g>
                  </g>
                  <g id="trees" transform="translate(0.000000, 16.000000)">
                    <g id="big" transform="translate(7.000000, 4.000000)">
                      <rect id="trunk" fill="#000000" x="6.3" y="18.7021277" width="1.4" height="14.2978723" />
                      <path d="M7,26.5531915 C10.8659932,26.5531915 14,23.5049174 14,19.7446809 C14,15.9844443 10.8659932,-1.42108547e-14 7,-1.42108547e-14 C3.13400675,-1.42108547e-14 0,15.9844443 0,19.7446809 C0,23.5049174 3.13400675,26.5531915 7,26.5531915 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#03EBB0" />
                    </g>
                    <g id="small">
                      <rect id="trunk" fill="#000000" x="6.3" y="22.7358491" width="1.4" height="14.2641509" />
                      <path d="M7,31.245283 C10.8659932,31.245283 14,28.2041983 14,24.4528302 C14,20.7014621 10.8659932,-2.48689958e-14 7,-2.48689958e-14 C3.13400675,-2.48689958e-14 -1.42108547e-14,20.7014621 -1.42108547e-14,24.4528302 C-1.42108547e-14,28.2041983 3.13400675,31.245283 7,31.245283 Z" id="leaves" stroke="#000000" strokeWidth="1.5" fill="#03EBB0" />
                    </g>
                  </g>
                </g>
                <g id="car" transform="translate(0, 146)">
                  <g id="body" fill="#FC0B42">
                    <path d="M3.805846,4 C5.77651122,2.02015434 8.17456255,0.850097369 11,0.489829076 C13.8254374,0.129560783 16.3462708,1.29961776 18.5625,4 L20.625,7.77554336 L2.92669368,7.77554336 L3.805846,4 Z" />
                    <polygon points="16.84375 3.48982908 21.8947704 4.56794299 21.8947704 7.77554336 20.4740567 7.77554336" />
                    <path d="M0.458333333,4.56794299 L3.805846,4 L3.20833333,7.77554336 L0.458333333,7.77554336 C0.458333333,6.77554336 0.458333333,5.98982908 0.458333333,5.4184005 C0.458333333,4.84697193 0.458333333,4.56348609 0.458333333,4.56794299 Z" />
                  </g>
                  <circle id="wheel" stroke="#000000" strokeWidth="0.5" fill="#FFFFFF" cx="5.5" cy="7.5" r="1.5" />
                  <circle id="wheel" stroke="#000000" strokeWidth="0.5" fill="#FFFFFF" cx="16.5" cy="7.5" r="1.5" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

SkylineLandscape.propTypes = {
  chartWidth: PropTypes.number.isRequired,
};

export default SkylineLandscape;
