// Clouds
export const SKYLINE_ASSET_PATH_CLOUD_ONE = 'M46.3709624,19.9694398 C48.3364762,19.9582081 50.1756943,19.8247341 51.8886167,19.5690178 L51.8886165,19.5690168 C54.8914477,19.1207345 57.0861739,16.4999964 57.0003141,13.4651027 C56.9987055,13.4082446 56.9979013,13.3505644 56.9979013,13.2920622 C56.9979013,8.70537373 50.6075737,7.79649365 50.5024401,7.68610963 C48.1075522,5.17161967 44.3595469,2.10158867 41.0090739,2.58978913 C39.1555265,2.8598712 36.4907219,4.21889 33.0146602,6.66684553 C29.2225064,1.87107231 24.7256487,-0.337333245 19.5240871,0.0416288803 C14.8606792,0.38138358 11.0300226,4.79819468 8.03211748,13.2920622 C3.03560893,11.5932887 0.370804372,12.2727981 0.0377038021,15.3305904 C-0.295828377,18.3923448 1.58348752,19.9386279 5.67565149,19.9694398 C25.6752985,20.0101867 39.2404021,20.0101867 46.3709624,19.9694398 Z';
export const SKYLINE_ASSET_PATH_CLOUD_TWO = 'M36.8628552,20 C38.1570986,20 38.8635019,19.3653423 38.982065,18.096027 L38.9820857,18.0960289 C39.8337593,8.97816539 33.1326885,0.896258669 24.0148249,0.0445850078 C23.8345256,0.0277437607 23.6539625,0.0138572285 23.4732083,0.00293100012 C23.4732083,0.00293100012 23.4732083,0.00293100012 23.4732083,0.00293100012 C22.5566271,-0.052474477 18.655864,0.660675831 15.5386026,3.59214851 C14.1253392,4.9211815 11.9763835,7.99765365 9.09173559,12.821565 C3.69467931,11.269912 0.719202209,11.7826574 0.165304283,14.3598011 C-0.49591285,17.4362732 0.826521417,19.3163395 4.13260709,20 C19.6712097,20 30.5812924,20 36.8628552,20 Z';
export const SKYLINE_ASSET_PATH_CLOUD_THREE = 'M46.3210823,16 C46.4937016,16 46.6639619,15.996578 46.8318632,15.989734 L46.8318633,15.989737 C49.039171,15.8997625 50.7556086,14.0374473 50.6656341,11.8301396 C50.6099221,10.4633814 49.8596524,9.21986441 48.6765202,8.53332951 L46.4561917,7.24494182 C47.4617345,4.32751558 46.9589631,2.38256476 44.9478776,1.41008935 C41.379864,-0.315250327 39.0335976,0.819304321 37.9090785,4.81375329 C36.30153,3.84127788 35.1283968,3.35504017 34.3896789,3.35504017 C33.650961,3.35504017 32.6454183,3.84127788 31.3730507,4.81375329 C29.3053645,2.63531736 27.294279,1.50076272 25.3397943,1.41008935 C24.3333492,1.36339788 22.9926255,1.68755635 21.3176233,2.38256476 C18.6361761,0.113455465 16.2899097,-0.534861477 14.2788242,0.437613935 C11.56636,1.74924627 10.2256363,4.01835557 10.2566533,7.24494182 C4.2460278,6.18244203 0.894218679,7.15491745 0.201225892,10.1623681 C-0.626036107,13.7525203 1.09742639,15.6983976 5.37161338,16 C24.8122703,16 38.4620933,16 46.3210823,16 Z';
export const SKYLINE_ASSET_PATH_SUN = 'M11,22 C17,22 22,17.0751322 22,11 C22,4.92486775 17.0751322,0 11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 5,22 11,22 Z';

// Stars
export const SKYLINE_ASSET_PATH_STAR_HORIZONTAL = 'M6,3 L0,3';
export const SKYLINE_ASSET_PATH_STAR_VERTICAL = 'M3,0 L3,6';

// Rain
export const SKYLINE_ASSET_PATH_RAIN_DROPLET = 'M5,10 L5.5,12.5';

// Snow
export const SKYLINE_ASSET_PATH_SNOW_FLAKE = 'M0.500498746,1 C0.776641121,1 1.00049875,0.776142375 1.00049875,0.5 C1.00049875,0.223857625 0.776641121,0 0.500498746,0 C0.224356371,0 -2.35367281e-14,0.223857625 -2.35367281e-14,0.5 C-2.35367281e-14,0.776142375 0.224356371,1 0.500498746,1 Z';
